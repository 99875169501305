import api from '@/services/Api'

export default {
    view (codeMs) { // Voir une entrée spécifique
        return api().get('/report')
    },
    viewUqam (codeMs) {
        return api().get('/report/uqam')
    },
    clientFacture (annee, contratId) {
        return api().get(`/report/clientFacture/${annee}/${contratId}`)
    },
    responsableUbrFacture (periode, ubr) {
        return api().get(`/report/responsableUbrFacture/${periode}/${ubr}`)
    },
}
