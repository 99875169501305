import api from '@/services/Api'

export default {
    list () { // Liste tout
        return api().get('/factures')
    },
    save (facture) { // Création d'une nouvelle entrée
        return api().post('/factures', facture)
    },
    view (factureId) { // Voir une entrée spécifique
        return api().get(`/factures/${factureId}`)
    },
    update (facture, factureId) { // Mise à jour d'une entrée spécifique
        return api().put(`/factures/${factureId}`, facture)
    },
    remove (factureId) { // Suppression d'une entrée spécifique
        return api().delete(`/factures/${factureId}`)
    },
    search (item) { // Recherche
        return api().get(`/factures/search/${item}`)
    },
    query (item, filter) { // Recherche spécifique
        return api().get(`/factures/query/${encodeURIComponent(item)}?champ=${filter}`)
    },
    callsDetail (item) { // Recherche spécifique
      return api().get(`/factures/callsDetail/${item}`)
    },
    autreList (item) { // Recherche spécifique
      return api().get(`/factures/autre/${item}`)
    },
    autreCreate (facture) { // Création d'une nouvelle entrée
      return api().post('/factures/autre', facture)
    },
    paiementErreur () { // List les factures en erreur
      return api().get('/factures/paiementErreur')
    },
}
